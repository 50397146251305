import React from "react"
import style from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"
import NavButton from "../Buttons/NavButton"
import HeroDetails from "./HeroDetails"

export default () => (
  <section className={style.pricingSection} id="pricing">
    <div className={style.pricingHeaderImg}></div>
    <div className={style.container}>
      <Row center="xs">
        <Col xs={12} className={style.pricingTitle}>
          <h1>Pricing tailored for your business</h1>
          <h2>Consumption-based plan that suits your needs</h2>
        </Col>
        <HeroDetails>
          <span className={style.acount}>Developer</span>
          <div className={style.bar} />
          <span className={style.price}>Free</span>
          <span className={style.include}>includes:</span>
          <span className={style.features}>Up to 5 Devices</span>
          <span className={style.features}>Up to 1 GB Bandwidth</span>
          <span className={style.features}>Up to 1 GB Storage</span>
          <span className={style.infoFree}>Completelly free of charge.</span>
          <span className={style.info}>No credit card required.</span>
        </HeroDetails>
        <HeroDetails>
          <span className={style.acount}>Organization</span>
          <div className={style.bar} />
          <span className={style.price}>Metered Billing</span>
          <span className={style.include}>includes:</span>
          <span className={style.features}>Unlimited Devices</span>
          <span className={style.features}>Unlimited Bandwidth</span>
          <span className={style.features}>Unlimited Storage</span>
          <span className={style.features}>Manage Organizations</span>
          <span className={style.features}>Manage Products</span>
          <span className={style.infoBilling}>Starting at $10 / month</span>
        </HeroDetails>
      </Row>
      <Col xs={12} className={style.pricingButton}>
        <NavButton text={"GET STARTED FREE"} link={"https://auth.updatehub.io/auth/signup"} />
      </Col>
    </div>
  </section>
)
