import React from "react"
import style from "./Hero.module.scss"
import PricingData from "./PricingData"
import { Col } from "react-flexbox-grid"

export default () => (
  <Col xs={12} className={style.showXs}>
    <div className={`${style.detailedPricingBox} ${style.borderTop}`}>
      {PricingData.map((pricing, index) => (
        <div key={index} className={style.item}>
          <h4>
            <img
              className={style.icon}
              src={pricing.type_icon}
              alt={`${pricing.type_name} icon`}
            />
            {pricing.type_name}
          </h4>
          <table className={style.pricingTable}>
            <tbody>
              {pricing.stages.map((stage, index) => (
                <tr key={index}>
                  <td>{stage.name}</td>
                  <td>
                    {stage.price_prefix}
                    <span className={style.price}> {stage.price}</span>
                    {stage.price_suffix}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
      <span className={style.labelPrice}>
        Reference prices for 100.000 devices.
        <br />
        Minimum $10/month.
      </span>
    </div>
  </Col>
)
