import React from "react"
import style from "./Hero.module.scss"
import { Col } from "react-flexbox-grid"

export default ({ children }) => (
  <Col xs={12} sm={6} md={4}>
    <Col xs={12} center="xs" className={style.pricingHeader}>
      {children}
    </Col>
  </Col>
)
