import React from "react"
import style from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"
import IconButton from "../Buttons/IconButton"
import PricingTableDetail from "./PricingTableDetail"
import PricingTableDetailMobile from "./PricingTableDetailMobile"

export default () => (
  <div className={style.pricingDetailsSection}>
    <div className={style.container}>
      <Row>
        <Col xs={12} sm={12} md={12} className={style.detailedDescBox}>
          <h3> Metered Billing </h3>
          <p>Charged monthly based on consumption</p>
        </Col>
        <PricingTableDetail />
        <PricingTableDetailMobile />
        <Col xs={1} />
        <Col xs={10} sm={12} md={12} className={style.detailedDescBox}>
          <span> Do you want help calculating your monthly billing? </span>
        </Col>
        <Col xs={12} className={style.showXs}>
          <Col xs={12} className={style.pricingButton}>
            <IconButton
              text="Estimate your costs"
              link="/calculator"
              img="/imgs/pricing/settings.png"
            />
          </Col>
        </Col>
        <Col xs={12} className={style.hideXs}>
          <Col xs={12} className={style.pricingButton}>
            <IconButton
              text="Estimate your costs"
              link="/estimate/#estimate"
              img="/imgs/pricing/settings.png"
            />
          </Col>
        </Col>
      </Row>
    </div>
  </div>
)
