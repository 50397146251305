import React from "react"
import styles from "./Buttons.module.scss"

const IconButton = ({ text, link, img }) =>
  <a
    className={`${styles.button} ${styles.buttonNav} ${styles.buttonLg}`}
    href={link}
    rel="noopener noreferrer"
    target="_blank"
  >
    <img src={img} alt="button icon" className={styles.icon} />
    {text}
  </a>

export default IconButton
