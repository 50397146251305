const PricingData = [
  {
    type_icon: "/imgs/pricing/devices.png",
    type_name: "Devices",
    stages: [
      {
        name: "Activation (one-time fee)",
        price_prefix: "Starting from",
        price: "$ 0.44",
        price_suffix: " / device",
      },
      {
        name: "Monthly device fee",
        price_prefix: "Starting from",
        price: "$ 0.0521",
        price_suffix: " / device",
      },
    ],
  },
  {
    type_icon: "/imgs/pricing/update.png",
    type_name: "Updates",
    stages: [
      {
        name: "Unlimited",
        price_prefix: "Starting from",
        price: "$ 0.0695",
        price_suffix: " / deployed device",
      },
    ],
  },
  {
    type_icon: "/imgs/pricing/storage.png",
    type_name: "Storage",
    stages: [
      {
        name: "Unlimited",
        price_prefix: "",
        price: "$ 0.05",
        price_suffix: " / GB",
      },
    ],
  },
  {
    type_icon: "/imgs/pricing/bandwidth.png",
    type_name: "Bandwidth",
    stages: [
      {
        name: "Unlimited",
        price_prefix: "",
        price: "$ 0.095",
        price_suffix: " / GB",
      },
    ],
  },
]

export default PricingData
