import React from "react"
import style from "./Hero.module.scss"
import PricingData from "./PricingData"
import { Col } from "react-flexbox-grid"

export default () => (
  <Col sm={12} md={12} className={style.hideXs}>
    <div>
      <table className={`${style.detailedPricingTable} ${style.borderTop}`}>
        <tbody>
          {PricingData.map((pricing, index) => (
            <tr key={index}>
              <td>
                <img
                  src={pricing.type_icon}
                  alt={`${pricing.type_name} icon`}
                />
              </td>
              <td className={style.description}>{pricing.type_name}</td>
              <td>
                <table className={style.pricingTable}>
                  <tbody>
                    {pricing.stages.map((stage, index) => (
                      <tr key={index}>
                        <td>{stage.name}</td>
                        <td>
                          {stage.price_prefix}
                          <span className={style.price}> {stage.price}</span>
                          {stage.price_suffix}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <span className={style.labelPrice}>
        Reference prices for 100.000 devices. Minimum $10/month.
      </span>
    </div>
  </Col>
)
